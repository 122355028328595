import { Element, HTMLReactParserOptions, domToReact } from "html-react-parser";
import { cssStringToCssProps } from "../helpers/editor-content.helper";

const splitWidthfromStyleString = (styleString: string = "") => {
  const styleStringArray = styleString.split(";");
  const widthString = styleStringArray.find((style) => style.includes("width"));
  const width = widthString?.split(":")[1];
  const style = styleStringArray.filter((style) => !style.includes("width")).join(";");

  return { width, style };
};

export const mapToFigure = (node: Element, options: HTMLReactParserOptions, additionalStyleString?: string) => {
  const initialCssProps = cssStringToCssProps(node.attribs.style) || {};
  let additionalCssProps = cssStringToCssProps(additionalStyleString);
  let isShowFigure = false;

  if (node.attribs?.class?.includes("image") && !node.attribs?.class?.includes("image-style-side")) {
    isShowFigure = true;
    additionalCssProps = {
      ...additionalCssProps,
      display: "flex",
      justifyContent: "center",
      margin: 0,
      padding: 0,
    };
    initialCssProps.width = splitWidthfromStyleString(node.attribs.style).width;
    (node.children[0] as Element).attribs.width = initialCssProps.width ?? "100%";
    (node.children[0] as Element).attribs.style = initialCssProps.width
      ? `"width:${initialCssProps.width};"`
      : "width:100%;";
  } else if (node.attribs?.class?.includes("image") && node.attribs?.class?.includes("image-style-side")) {
    isShowFigure = true;
    additionalCssProps = {
      ...additionalCssProps,
      float: "right",
      display: "flex",
      justifyContent: "flex-end",
      margin: 0,
      padding: 0,
    };

    initialCssProps.width = splitWidthfromStyleString(node.attribs.style).width;
    (node.children[0] as Element).attribs.width = initialCssProps.width ?? "100%";
    (node.children[0] as Element).attribs.style = initialCssProps.width
      ? `"width:${initialCssProps.width};"`
      : "width:100%;";
  } else if ((node.children?.[0] as Element)?.name === "img") {
    isShowFigure = true;
    additionalCssProps = {
      ...additionalCssProps,
      display: "flex",
      justifyContent: "flex-start",
      margin: 0,
      padding: 0,
    };
    initialCssProps.width = splitWidthfromStyleString((node.children[0] as Element).attribs.style).width;
    (node.children[0] as Element).attribs.width = "100%";
    (node.children[0] as Element).attribs.style = "width:100%;";
    (node.children[0] as Element).attribs.width = initialCssProps.width ?? "100%";
    (node.children[0] as Element).attribs.style = initialCssProps.width
      ? `"width:${initialCssProps.width};"`
      : "width:100%;";
  } else if ((node.children?.[0] as Element)?.name === "table") {
    const tableStyle = splitWidthfromStyleString((node.children[0] as Element).attribs.style).style;
    // Set width to 100% since figure already includes the defined width
    (node.children[0] as Element).attribs.width = "100%";
    (node.children[0] as Element).attribs.style = tableStyle;
  }

  if (isShowFigure) {
    return (
      <figure style={{ ...additionalCssProps, width: initialCssProps?.width }}>
        <div style={{ width: initialCssProps?.width }}>{domToReact(node.children, options)}</div>
      </figure>
    );
  }

  if ((node.children[0] as Element | undefined)?.name === "table") {
    initialCssProps.overflowX = "auto";
    if (initialCssProps.width) {
      (node.children[0] as Element).attribs.width = `${initialCssProps.width}`;
    }
  }

  return (
    <div style={{ width: initialCssProps?.width, overflowX: initialCssProps.overflowX }}>
      {domToReact(node.children, options)}
    </div>
  );
};

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import React, { useContext, createContext, useState, useEffect } from "react";
import { SESSION_STORAGE_KEY } from "../../models/storage-keys";
import { config } from "../../utils/config.util";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { AccessTokenContext } from "../../app/context/access-token.context";
// @ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

interface GraphQLContextType {
  client: ApolloClient<NormalizedCacheObject>;
}

const GraphQLContext = createContext<GraphQLContextType>({} as GraphQLContextType);

export const GraphQLProvider = (props: any) => {
  const { accessToken } = useContext(AccessTokenContext);

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(
    generateGraphqlClient({
      accessToken,
    }),
  );

  useEffect(() => {
    setClient(generateGraphqlClient({ accessToken }));
  }, [accessToken]);

  return (
    <GraphQLContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </GraphQLContext.Provider>
  );
};

interface IGenerateGraphQLClientOptions {
  accessToken?: string;
}

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    if (graphQLErrors.find((error) => error.message === "Unauthorized")) {
      const queryParams = new URLSearchParams(window.location.search);
      const urlParamToken = queryParams.has("token") ? queryParams.get("token") : null;
      if (!urlParamToken) {
        sessionStorage.removeItem(SESSION_STORAGE_KEY.MUU_TOKEN);
      }
    }
  }
});

const generateGraphqlClient = (options: IGenerateGraphQLClientOptions) => {
  const { accessToken } = options;
  const muuApiLink = createUploadLink({
    uri: config.REACT_APP_GRAPHQL_URL,
    credentials: "include",
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.REACT_APP_GRAPHQL_WEBSOCKET_URL,
      connectionParams: {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    muuApiLink,
  );

  return new ApolloClient({
    link: ApolloLink.from([errorLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Person: {
          keyFields: ["id"],
          fields: {
            personExtra: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: "PersonExtra",
                  id: args?.id,
                });
              },
            },
          },
        },
        MenuSection: {
          keyFields: ["id", "language"],
        },
        MenuItem: {
          keyFields: ["id", "language"],
        },
        LegalDocumentGroup: {
          keyFields: ["id", "language"],
        },
        LegalRegisterTermDefinition: {
          keyFields: ["language"],
        },
        Category: {
          keyFields: ["id", "language"],
        },
        Query: {
          fields: {
            getAllPosts: {
              merge: true,
            },
          },
        },
      },
    }),
  });
};

import { Grid } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { FavoriteType, SearchEvent, useCategoriesQuery, useFavoritesQuery } from "../../api/muu-api/graphql/generated";
import { EventCard } from "./card/event.card";
import { LoadingCard } from "../../components/loading/loading-card.component";
import {
  DEFAULT_CARD_MIN_WIDTH,
  OVERVIEW_LOADING_CARD_HEIGHT,
  OVERVIEW_SPACING_FACTOR,
} from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { NoContentFound } from "../../components/error/no-content-found.component";
import { ApolloError } from "@apollo/client";
import { UserContext } from "../../app/context/user.context";
import { useColumnLayout } from "../../hooks/use-column-layout.hook";
import { useFavorites } from "../../hooks/use-favorites.hook";
import { eventSourceToFavoriteSource } from "../../components/widgets/events/helper/event-mapping.helper";

interface IEventOverviewProps {
  results: SearchEvent[];
  loading: boolean;
  error?: ApolloError;
}

export const EventOverview: React.FC<IEventOverviewProps> = (props) => {
  const { results, loading, error } = props;
  const { breakpoint } = useContext(LayoutContext);
  const { language } = useContext(UserContext);

  const { columns } = useColumnLayout();

  const { data: categories, loading: loadingCategories } = useCategoriesQuery({
    variables: {
      language,
    },
  });

  const { favoritesDict } = useFavorites({ type: FavoriteType.EVENT }, !results);

  const isLoading = useMemo(() => {
    return loading || loadingCategories;
  }, [loading, loadingCategories]);

  return (
    <Grid item container id="event-overview">
      {isLoading && (
        <Grid container spacing={OVERVIEW_SPACING_FACTOR[breakpoint]}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} md={6} key={`event-loading-${index}`}>
              <LoadingCard height={OVERVIEW_LOADING_CARD_HEIGHT[breakpoint]} />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoading && results.length <= 0 && !error && <NoContentFound variant="eventOverview" />}
      {error && (
        <Grid item xs={12}>
          <NoContentFound variant="eventOverviewError" />
        </Grid>
      )}
      {!isLoading && results && categories && (
        <Grid container spacing={OVERVIEW_SPACING_FACTOR[breakpoint]} id="event-overview-cards" columns={columns}>
          {results.map((card) => {
            return (
              <Grid item key={card.id} minWidth={DEFAULT_CARD_MIN_WIDTH[breakpoint]} xs={6}>
                <EventCard
                  eventEntry={card}
                  favorite={
                    favoritesDict?.[FavoriteType.EVENT]?.[eventSourceToFavoriteSource(card.eventType)]?.[card.id]
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

import { useApolloClient } from "@apollo/client";
import {
  Favorite,
  FavoritesDocument,
  FavoritesForUserDocument,
  SearchEventByFavoriteDocument,
  useCreateFavoritesMutation,
  useRemoveFavoriteMutation,
} from "../api/muu-api/graphql/generated";
import { EMPTY_FAVORITE_ID, ICreateFavoriteInput } from "../components/favorite/types/favorite.type";
import { useCallback } from "react";
import { writeToFavoritesCache } from "../components/favorite/helper/favorite-cache.helper";
import { v4 } from "uuid";
import { isFavoriteStub } from "../components/favorite/helper/is-favorite-stub.helper";

export const useFavorite = () => {
  const [createFavoritesMutation] = useCreateFavoritesMutation();
  const [removeFavoriteMutation] = useRemoveFavoriteMutation();
  const client = useApolloClient();

  const getEmptyFavorite = useCallback((data: ICreateFavoriteInput): Favorite => {
    return {
      __typename: "Favorite",
      entryId: data.entryId,
      type: data.type,
      source: data.source,
      description: data.description,
      userId: EMPTY_FAVORITE_ID,
      id: `${EMPTY_FAVORITE_ID}-${v4()}`,
      teaser: data.teaser ?? "",
      createdAt: new Date(),
      link: data.link ?? "",
    };
  }, []);

  const createFavorite = useCallback(
    async (data: ICreateFavoriteInput) => {
      writeToFavoritesCache(client, (favorites) => {
        favorites.push(getEmptyFavorite(data));
        return favorites;
      });
      createFavoritesMutation({
        variables: { favorites: [data] },
        onCompleted(data) {
          const favorite = data.createFavorites[0];

          writeToFavoritesCache(client, (favorites) => {
            const filtered = favorites.filter((fav) => !isFavoriteStub(fav));
            filtered.push(favorite);
            return filtered;
          });

          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: "searchEventByFavorite" });
            },
            include: [FavoritesForUserDocument, FavoritesDocument],
          });
        },
      });
    },
    [client, createFavoritesMutation, getEmptyFavorite],
  );

  const removeFavorite = useCallback(
    async (favorite: Favorite) => {
      writeToFavoritesCache(client, (favorites) => {
        return favorites.filter((fav) => fav.id !== favorite.id);
      });
      removeFavoriteMutation({
        variables: { id: favorite.id },
        onCompleted() {
          client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: "searchEventByFavorite" });
            },
            include: [FavoritesForUserDocument, FavoritesDocument],
          });
        },
      });
    },
    [client, removeFavoriteMutation],
  );

  return { createFavorite, removeFavorite, getEmptyFavorite };
};

import { Box, Card, CardContent, CardHeader, CardProps, Grid, Typography, useTheme } from "@mui/material";
import React, { ForwardedRef, PropsWithChildren, forwardRef, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../assets/icons/icons.component";
import { DashboardWidgetsContext } from "../../pages/dashboard/widget-section/dashboard-widgets.context";
import { IWidgetActionItemDictionaryEntry } from "../../pages/dashboard/widget-section/dictionaries/widget-action.dictionary";
import { IWidgetMenuItemDictionaryEntry } from "../../pages/dashboard/widget-section/dictionaries/widget-menu.dictionary";
import { getActionItemsByWidgetType } from "../../pages/dashboard/widget-section/helpers/get-action-items-by-widget-type.helper";
import { getMenuItemsByWidgetType } from "../../pages/dashboard/widget-section/helpers/get-menu-items-by-widget-type.helper";
import { getWidgetIcon } from "../../pages/dashboard/widget-section/helpers/get-widget-icon.helper";
import { useWidgetColorToThemeColor } from "../../pages/dashboard/widget-section/hooks/use-widget-color-to-theme-color.hook";
import { IExtendedWidget } from "../../pages/dashboard/widget-section/types/widget.type";
import {
  WIDGET_ACTIVE_DRAGGING_BORDER,
  WIDGET_HEADER_HEIGHT,
  WIDGET_HEIGHT,
  WIDGET_INACTIVE_DRAGGING_BORDER,
} from "../../theme/sizings.theme";
import { CustomIconButton } from "../icon/custom-icon-button.component";
import { LayoutContext } from "../layout/layout.context";
import { SelectionPopover } from "../popover/selection-popover";
import { MenuItemDialog } from "./menu-item.dialog";
import { WidgetContextWrapper } from "./widget-context-wrapper.component";
import { WidgetActions } from "./widget-actions.component";
import { getWidgetContentByType } from "../../pages/dashboard/widget-section/helpers/get-widget-content-by-type.helper";
import { WidgetTitle } from "../../pages/dashboard/widget-section/widget-title.component";

export interface IWidgetStyleConfiguration {
  color: string;
  backgroundColor: string;
}

interface IOpenMenuItem {
  key: string;
  isOpen: boolean;
}

export interface IWidgetProps extends PropsWithChildren, CardProps {
  widget: IExtendedWidget;
  title: string;
  headerActions?: JSX.Element[];
  isFixedWidget?: boolean;
  isSmall?: boolean;
}

export const Widget = forwardRef((props: IWidgetProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { isInEditMode, removeWidget, currentlyDraggingWidget } = useContext(DashboardWidgetsContext);
  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { headerActions, widget, title, sx, children, isFixedWidget = false, isSmall = false, ...other } = props;
  const theme = useTheme();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [isOptionOpen, setIsOptionOpen] = useState<IOpenMenuItem | null>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { t } = useTranslation();
  const widgetThemeColor = useWidgetColorToThemeColor(widget.color, isInEditMode && !isFixedWidget);

  const { color, backgroundColor } = useMemo(() => {
    const color = `${widgetThemeColor}.contrastText`;
    const backgroundColor = `${widgetThemeColor}.main`;

    return { color, backgroundColor };
  }, [widgetThemeColor]);

  const menuItems: IWidgetMenuItemDictionaryEntry[] = useMemo(() => getMenuItemsByWidgetType(widget.type), [widget]);

  const widgetActions: IWidgetActionItemDictionaryEntry[] = useMemo(
    () => getActionItemsByWidgetType(widget.type),
    [widget],
  );

  const menuOptions = useMemo(() => {
    return menuItems.map((item) => {
      return {
        key: item.id.toString(),
        label: t(item.translationKey),
        icon: item.icon,
      };
    });
  }, [menuItems, t]);

  const actions = useMemo(() => {
    return headerActions?.map((action, index) => (
      <Grid item key={`header-actions-${index}`}>
        {action}
      </Grid>
    ));
  }, [headerActions]);

  const onSettingsIconClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setMenuAnchorElement(event.currentTarget),
    [],
  );

  const onClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, []);

  const onCloseMenuItem = useCallback(() => {
    setIsOptionOpen(null);
    // close dialog and options menu
    onClose();
  }, [onClose]);

  const getMenuItemComponent = useCallback(
    (id: string | undefined) => {
      if (!id) return () => <></>;
      const menuItem = menuItems.find((item) => item.id.toString() === id);
      if (!menuItem) return () => <></>;

      return menuItem.Component;
    },
    [menuItems],
  );

  const onDeleteWidgetClicked = useCallback(() => {
    removeWidget(widget.id);
  }, [widget, removeWidget]);

  const borderStyle = useMemo(
    () =>
      isInEditMode && !isFixedWidget
        ? {
            borderWidth:
              currentlyDraggingWidget?.id === widget.id
                ? WIDGET_ACTIVE_DRAGGING_BORDER
                : WIDGET_INACTIVE_DRAGGING_BORDER,
            borderStyle: "solid",
            borderColor: "accent6.main",
          }
        : undefined,
    [isInEditMode, currentlyDraggingWidget, widget, isFixedWidget],
  );

  return (
    <WidgetContextWrapper actions={widgetActions}>
      <SelectionPopover
        options={menuOptions}
        open={!!menuAnchorElement}
        anchorEl={menuAnchorElement}
        onClose={onClose}
        onOptionSelected={(option) => setIsOptionOpen({ key: option.key, isOpen: true })}
        selectedKey={null}
      />
      <MenuItemDialog
        widget={widget}
        open={!!isOptionOpen?.isOpen}
        onClose={onCloseMenuItem}
        Component={getMenuItemComponent(isOptionOpen?.key)}
      />

      <Card
        sx={{
          ...sx,
          borderRadius: 4,
          ...borderStyle,
          height: WIDGET_HEIGHT[breakpoint],
          boxShadow: "0px 16px 32px -4px rgba(145, 158, 171, 0.16)",
          position: "relative",
        }}
        elevation={0}
        {...other}
        onMouseEnter={() => setIsHovering(isInEditMode)}
        ref={ref}
      >
        <CardHeader
          id={`widget-header-${widget.type}`}
          sx={{
            backgroundColor,
            padding: theme.spacing(0, 4),
            height: WIDGET_HEADER_HEIGHT[breakpoint],
          }}
          title={
            <Box sx={{ display: "block", flexGrow: 1 }}>
              <Grid container alignItems="center" justifyContent="flex-start" flexWrap="nowrap">
                <Grid item sx={{ pr: breakpointInfo.isMobile ? 2 : 3, textAlign: "center" }}>
                  {getWidgetIcon(widget, widgetThemeColor)}
                </Grid>
                <Grid item sx={{ textAlign: "center" }}>
                  <Typography variant={breakpointInfo.isMobile ? "body1" : "h6"} fontWeight={700} color={color}>
                    <WidgetTitle widget={widget} title={title} />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          }
          action={
            <WidgetActions
              widget={widget}
              isEditMode={isInEditMode}
              color={color}
              widgetActions={widgetActions}
              actions={actions}
              onClickSettings={onSettingsIconClicked}
              isSmall={isSmall}
            />
          }
        />
        <CardContent
          sx={{
            p: 0,
            "&:last-child": { pb: 0 },
            height: WIDGET_HEIGHT[breakpoint] - WIDGET_HEADER_HEIGHT[breakpoint],
            display: "flex",
            position: "relative",
          }}
        >
          {getWidgetContentByType(widget, isSmall)}
        </CardContent>
        {isInEditMode && isHovering && !isFixedWidget && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "accent6.main",
              borderRadius: 3,
            }}
            onMouseLeave={() => setIsHovering(false)}
          />
        )}
        {isInEditMode && !isFixedWidget && (
          <Box
            sx={{
              top: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              height: WIDGET_HEADER_HEIGHT,
            }}
          >
            <CustomIconButton onClick={onDeleteWidgetClicked}>
              <CloseIcon sx={{ color: "accent6.contrastText" }} fontSize="large" />
            </CustomIconButton>
          </Box>
        )}
      </Card>
    </WidgetContextWrapper>
  );
});

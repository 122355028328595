import { ApolloError, useApolloClient } from "@apollo/client";
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  FixedWidget,
  Widget,
  WidgetColor,
  WidgetType,
  WidgetsDocument,
  useUpdateWidgetMutation,
  useUpdateWidgetsMutation,
  useWidgetsQuery,
} from "../../../api/muu-api/graphql/generated";
import { LayoutContext } from "../../../components/layout/layout.context";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar.context";
import { changeWidgetPosition } from "./helpers/move-widget.helper";
import { removeEmptyRows } from "./helpers/remove-empty-rows.helper";
import {
  EDIT_DASHBOARD_PATH,
  ICurrentlyDraggingWidget,
  IExtendedWidget,
  IWidgetOptions,
  UNCREATED_FIXED_WIDGET_ID,
} from "./types/widget.type";
import { StaticPageDictionary } from "../../../router/routes.const";

interface IDashboardWidgetsContext {
  widgets: IExtendedWidget[];
  fixedWidgets: IExtendedWidget[];
  rows: IExtendedWidget[][];
  fixedRows: IExtendedWidget[][];
  currentlyDraggingWidget?: ICurrentlyDraggingWidget;
  setCurrentlyDraggingWidget: (value: ICurrentlyDraggingWidget | undefined) => void;
  moveWidget: (id: string, row: number, column: number) => void;
  removeWidget: (id: string) => void;
  widgetOptions: IWidgetOptions | null;
  setWidgetOptions: (value: IWidgetOptions | null) => void;
  isInEditMode: boolean;
  addWidget: (type: WidgetType) => void;
  saveWidgets: () => void;
  saveWidget: (widget: IExtendedWidget) => void;
  cancelWidgetUpdate: () => void;
  setWidgetCategories: (id: string, categoryIds: string[]) => void;
  setWidgetRestaurants: (id: string, restaurantIds: number[]) => void;
  scrollToLastRow: boolean;
  setScrollToLastRow: (value: boolean) => void;
}

export const DashboardWidgetsContext = createContext<IDashboardWidgetsContext>({
  widgets: [],
  fixedWidgets: [],
  rows: [],
  fixedRows: [],
  setCurrentlyDraggingWidget: () => null,
  moveWidget: () => null,
  removeWidget: () => null,
  addWidget: () => null,
  widgetOptions: null,
  setWidgetOptions: () => null,
  isInEditMode: false,
  saveWidgets: () => null,
  saveWidget: () => null,
  cancelWidgetUpdate: () => null,
  setWidgetCategories: () => null,
  setWidgetRestaurants: () => null,
  scrollToLastRow: false,
  setScrollToLastRow: () => null,
});

interface IDashboardWidgetsContextProviderProps extends PropsWithChildren {}

export const DashboardWidgetsContextProvider: FC<IDashboardWidgetsContextProviderProps> = ({ children }) => {
  const { editDashboardEnabled } = useContext(LayoutContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [widgets, setWidgets] = useState<IExtendedWidget[]>([]);
  const [fixedWidgets, setFixedWidgets] = useState<IExtendedWidget[]>([]);
  const [currentlyDraggingWidget, setCurrentlyDraggingWidget] = useState<ICurrentlyDraggingWidget | undefined>(
    undefined,
  );
  const [widgetOptions, setWidgetOptions] = useState<IWidgetOptions | null>(null);
  const isInEditMode = useMemo(() => search.includes(EDIT_DASHBOARD_PATH), [search]);
  const [scrollToLastRow, setScrollToLastRow] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const client = useApolloClient();

  const onFixedWidgetsQueryCompleted = useCallback((fixedWidgets: FixedWidget[], widgets: Widget[]) => {
    const mappedFixedWidgets: IExtendedWidget[] = [];
    for (const fixedWidget of fixedWidgets) {
      const userWidgetOfSameType = widgets.find((widget) => widget.type === fixedWidget.type);
      const userCategories = (userWidgetOfSameType?.categoryIds ?? [])
        .filter((id) => !fixedWidget.categoryIds.includes(id))
        .map((id) => {
          return { id, isLocked: false };
        });
      const fixedCategories = fixedWidget.categoryIds.map((id) => {
        return { id, isLocked: true };
      });
      mappedFixedWidgets.push({
        __typename: "Widget",
        id: userWidgetOfSameType?.id ?? UNCREATED_FIXED_WIDGET_ID,
        row: fixedWidget.row,
        column: fixedWidget.column,
        categoryFilter: [...fixedCategories, ...userCategories],
        color: userWidgetOfSameType?.color ?? WidgetColor.DEFAULT,
        restaurantIds: userWidgetOfSameType?.restaurantIds ?? [],
        type: fixedWidget.type,
        isFixedWidget: true,
      });
    }
    setFixedWidgets(mappedFixedWidgets);
  }, []);

  const onWidgetsQueryCompleted = useCallback((widgets: Widget[], fixedWidgets: FixedWidget[]) => {
    const fixedTypes = fixedWidgets.map((fixedWidget) => fixedWidget.type);
    const filteredWidgets = widgets
      .filter((widget) => !fixedTypes.includes(widget.type))
      .map((widget) => {
        return {
          ...widget,
          isFixedWidget: false,
          categoryFilter: widget.categoryIds.map((id) => {
            return { id, isLocked: false };
          }),
        };
      });
    setWidgets(filteredWidgets);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams({});

  useWidgetsQuery({
    onCompleted: (response) => {
      onWidgetsQueryCompleted(response.widgets.widgets, response.widgets.fixedWidgets);
      onFixedWidgetsQueryCompleted(response.widgets.fixedWidgets, response.widgets.widgets);
    },
  });

  const { cache } = useApolloClient();

  const [updateWidgetsMutation] = useUpdateWidgetsMutation({
    onError: (_error: ApolloError) => {
      showSnackbar(t("widget_section.update_error"), SnackbarSeverity.Error);
    },
    onCompleted: (response) => {
      cache.writeQuery({ query: WidgetsDocument, variables: {}, data: { widgets: response.updateWidgets } });
      onWidgetsQueryCompleted(response.updateWidgets.widgets, response.updateWidgets.fixedWidgets);
      onFixedWidgetsQueryCompleted(response.updateWidgets.fixedWidgets, response.updateWidgets.widgets);
      setSearchParams({});
    },
  });

  const [updateWidgetMutation] = useUpdateWidgetMutation({
    onError: (_error: ApolloError) => {
      showSnackbar(t("widget_section.update_error"), SnackbarSeverity.Error);
    },
    onCompleted: () => {
      client.refetchQueries({
        include: [WidgetsDocument],
      });
    },
  });

  const saveWidgets = useCallback(() => {
    const updatedWidgets = widgets.map((widget) => {
      // if widget was a fixed widget, we need to set new row and column
      return {
        id: widget.id,
        categoryIds: widget.categoryFilter.map((info) => info.id),
        restaurantIds: widget.restaurantIds,
        color: widget.color,
        column: widget.column,
        row: widget.row,
        type: widget.type,
        isFixedWidget: widget.isFixedWidget,
      };
    });
    updateWidgetsMutation({ variables: { widgets: updatedWidgets } });
  }, [updateWidgetsMutation, widgets]);

  const saveWidget = useCallback(
    (widget: IExtendedWidget) => {
      const isFixedWidget = fixedWidgets.map((fixedWidget) => fixedWidget.type).includes(widget.type);
      const updatedWidget = {
        id: widget.id,
        categoryIds: widget.categoryFilter.map((info) => info.id),
        restaurantIds: widget.restaurantIds,
        color: widget.color,
        column: widget.column,
        row: widget.row,
        type: widget.type,
        isFixedWidget,
      };
      updateWidgetMutation({
        variables: {
          widget: updatedWidget,
        },
      });
    },
    [updateWidgetMutation, fixedWidgets],
  );

  useEffect(() => {
    if (isInEditMode && !editDashboardEnabled) {
      navigate(`${StaticPageDictionary.DASHBOARD.path}`);
    }
  }, [editDashboardEnabled, isInEditMode, navigate]);

  const widgetsToRow = useCallback((widgets: IExtendedWidget[]) => {
    const rowsMap: Map<number, IExtendedWidget[]> = new Map();

    widgets
      .sort((a, b) => (a.row - b.row ? a.row - b.row : a.column - b.column))
      .forEach((widget) => {
        rowsMap.set(widget.row, (rowsMap.get(widget.row) || []).concat(widget));
      });

    return Array.from(rowsMap.keys()).map((key) => rowsMap.get(key) || []);
  }, []);

  // sorted array of rows containing widgets
  const fixedRows = useMemo(() => {
    return widgetsToRow(fixedWidgets);
  }, [fixedWidgets, widgetsToRow]);

  const rows = useMemo(() => {
    return widgetsToRow(widgets);
  }, [widgets, widgetsToRow]);

  const moveWidget = useCallback(
    (id: string, row: number, column: number) => {
      const copy = changeWidgetPosition(widgets, id, row, column);
      if (!copy) {
        return;
      }
      setWidgets(copy);
    },
    [widgets],
  );

  const removeWidget = useCallback(
    (id: string) => {
      setWidgets(removeEmptyRows([...widgets].filter((widget) => widget.id !== id)));
    },
    [widgets],
  );

  const addWidget = useCallback(
    (type: WidgetType) => {
      const lastWidget = widgets.at(-1);
      const id = v4();
      setWidgets([
        ...widgets,
        {
          id,
          column: 0,
          row: lastWidget ? lastWidget.row + 1 : 0,
          type,
          categoryFilter: [],
          restaurantIds: [],
          color: WidgetColor.DEFAULT,
          isFixedWidget: false,
          __typename: "Widget",
        },
      ]);
      setScrollToLastRow(true);
    },
    [widgets],
  );

  const setWidgetCategories = useCallback(
    (id: string, categoryIds: string[]) => {
      const currentWidget = widgets.find((widget) => widget.id === id);
      if (!currentWidget) return;

      const otherWidgets = widgets.filter((widget) => widget.id !== currentWidget.id);
      const lockedCategories = currentWidget.categoryFilter.filter((info) => info.isLocked);
      const newIds = categoryIds
        .filter((id) => !lockedCategories.map((info) => info.id).includes(id))
        .map((id) => {
          return { id, isLocked: false };
        });
      setWidgets([
        ...otherWidgets,
        {
          ...currentWidget,
          categoryFilter: [...lockedCategories, ...newIds],
        },
      ]);
    },
    [widgets],
  );

  const setWidgetRestaurants = useCallback(
    (id: string, restaurantIds: number[]) => {
      const currentWidget = widgets.find((widget) => widget.id === id);
      if (!currentWidget) return;

      const otherWidgets = widgets.filter((widget) => widget.id !== currentWidget.id);
      setWidgets([
        ...otherWidgets,
        {
          ...currentWidget,
          restaurantIds: restaurantIds,
        },
      ]);
    },
    [widgets],
  );

  const cancelWidgetUpdate = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return (
    <DashboardWidgetsContext.Provider
      value={{
        rows,
        fixedRows,
        widgets,
        fixedWidgets,
        currentlyDraggingWidget,
        setCurrentlyDraggingWidget,
        moveWidget,
        removeWidget,
        widgetOptions,
        setWidgetOptions,
        isInEditMode,
        addWidget,
        saveWidgets,
        saveWidget,
        cancelWidgetUpdate,
        setWidgetCategories,
        setWidgetRestaurants,
        scrollToLastRow,
        setScrollToLastRow,
      }}
    >
      {children}
    </DashboardWidgetsContext.Provider>
  );
};

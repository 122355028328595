import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";

interface IEventAttendanceInfoProps {
  startAdornment: React.ReactNode;
  title: string;
  description?: string | null;
}

export const EventAttendanceInfo: React.FC<IEventAttendanceInfoProps> = (props) => {
  const { startAdornment, title, description } = props;

  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item sx={{ mr: 2, display: "flex" }}>
        {startAdornment}
      </Grid>
      <Grid item>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      {description && (
        <Grid item>
          <Typography variant="body1" sx={{ pl: 1 }}>
            {description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

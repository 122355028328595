import {
  Language,
  NotificationState,
  Role,
  SearchFilterName,
  ThinkTankType,
  WidgetType,
} from "../api/muu-api/graphql/generated";
import { NONE_CATEGORY } from "../app/helpers/const.helper";
import { ProfileOptions } from "../components/layout/profile/profile.component";

export const TRANSLATIONS_EN = {
  dashboard: {
    welcome_text: "Med Uni MUniverse Application version {{version}}",
    edit_dashboard: "Edit Dashboard",
    add_widget: "Add Widget",
    add_widget_dialog: {
      title: "Add Widget",
      description: "Select a widget to add to the dashboard.",
      select_placeholder: "Choose Widget",
      cancel: "Cancel",
      add: "Add",
    },
    save_widgets: "Save",
    read_more: "Read more",
    tooltip: {
      language_selection: "Choose language",
      theme: "Activate/deactivate contrast theme",
      bell: "Notifications",
      profile: "Profile",
      search: {
        filter: "Category filter",
        glass: "Start search",
        clear: "Clear search history",
      },
      sidebar: "Show/hide side navigation",
      widget: {
        settings: "Widget settings",
        favorites: {
          add: "Add bookmark",
        },
        think_tank: {
          add: "Create post",
        },
        deeplink: "Copy link",
        events: {
          attendence_fee: "Chargeable",
          attendence_registration: "Registration required",
        },
        notifications: {
          filter: "Choose message status",
        },
      },
      favorite: {
        add: "Add bookmark",
        remove: "Remove bookmark",
      },
    },
  },
  error: {
    back_to_dashboard: "Back to MUniverse",
    401: {
      title: "Unauthorized",
      description:
        "You can not login to MUniverse with this account. Please use your personalized employee (o_) or student (os) account.",
    },
    403: {
      title: "Policy Error",
      description:
        "This content is only available to people who are registered in MUniverse and have access to the content.",
    },
  },
  public_transport: {
    language: "en_GB",
  },
  searchbar: {
    placeholder: "Search ...",
  },
  language_select: {
    short: {
      [Language.DE]: "DE",
      [Language.EN]: "EN",
    },
    [Language.DE]: "German",
    [Language.EN]: "English",
    update_error: "Language setting could not be saved.",
  },
  profile: {
    [ProfileOptions.EDIT_DASHBOARD]: "Edit Dashboard",
    [ProfileOptions.PROFILE_CARD]: "My data",
    [ProfileOptions.SETTINGS]: "Settings",
    save_dashboard: "Save Dashboard",
  },
  widgets: {
    [WidgetType.BUS_BAHN_BIM]: {
      title: "BusBahnBim",
    },
    [WidgetType.INFOCENTER]: {
      title: "Infocenter",
      no_data: "No entries available.",
      copy_link_success: "URL successfully copied.",
    },
    [WidgetType.EVENTS]: {
      title: "Events",
      no_data: "No events available.",
    },
    [WidgetType.FAVORITES]: {
      title: "Favorites",
      show_all: "Show all",
      no_data: "No favorites available.",
    },
    [WidgetType.NOTIFICATIONS]: {
      title: "Mailings",
      no_data: "No mailings available.",
      copy_link_success: "URL successfully copied.",
    },
    [WidgetType.THINK_TANK]: {
      title: "Pinnboard",
      no_data: "No entries available.",
      copy_link_success: "URL successfully copied.",
      no_category: "No categories available",
    },
    [WidgetType.LUNCH]: {
      title: "Lunch Menus",
      weekly_special: "Weekly Special",
      no_data: "No lunch menus available.",
      vegetarian: "vegetarian",
      vegan: "vegan",
    },
    [WidgetType.WORK_SPACES]: {
      title: "My Workspaces",
    },
    filter: {
      favorites: "show only favorites",
      favorites_short: "only favorites",
      selected_restaurants: "Selected restaurants",
      no_restaurants_selected: "No restaurants selected.",
    },
    menu_items: {
      color: "Change Widget color",
      category_filter: "Show selection",
      add_link_favorite: "Add favorite",
      edit_link_favorite: "Edit favorite",
      add_think_tank_post: "Create Pinnboard post",
      restaurant_filter: "Restaurant filter",
    },
    general: {
      show_all: "Show all",
    },
  },
  search: {
    search_results: "Search results",
    no_results: "No search results found.",
    search_string_too_short: "Enter at least 2 characters.",
    filter: {
      [SearchFilterName.ALL]: "All",
      [SearchFilterName.PERSONS]: "Persons",
      [SearchFilterName.ORGANIZATIONAL_UNITS]: "Organizational Units",
      [SearchFilterName.INFOCENTER]: "Infocenter",
      [SearchFilterName.EVENTS]: "Events",
      [SearchFilterName.NOTIFICATIONS]: "Mailings",
      [SearchFilterName.TOPIC_PAGES]: "Topics",
      [SearchFilterName.LEGAL_DOCUMENTS]: "Legal Register",
      [SearchFilterName.THINK_TANK]: "Pinnboard",
    },
  },
  person_detail: {
    profile: "Profile",
    organization_function: "Function",
    place_of_work: "{{address}}, Room {{room}}",
  },
  settings: {
    title: "Settings",
  },
  infocenter: {
    title: "Infocenter",
    related_links: "Related Links",
    research_fields: {
      start_date: "Start",
      link: "Info",
      mode: "Submission mode",
      deadline: "Submission deadline",
      funders: "Funders",
      endowment: "Endowment",
    },
  },
  events: {
    title: "Events",
    attendence_registration: "Registration required",
    attendence_fee: "Registration fee",
    organizer: "Organizer",
    location: "Location",
    contact_person: "Contact person",
    dfp_points: "DFP Points",
    website: "Website",
    registration: "Registration",
    registration_deadline_info: "possible until ",
    attachment_default_name: "Program",
  },
  filter: {
    daterange: {
      from: "From",
      to: "To",
      filter_label: "Restrict date",
    },
    category: {
      filter_label: "Show selection",
    },
  },
  general: {
    reset_filter: "Reset selection",
    apply: "Apply",
    save: "Save",
    save_changes: "Save changes",
    cancel: "Cancel",
    back_to_overview: "Back to overview",
    back_to_dashboard: "Back to dashboard",
    copy_link_success: "URL successfully copied.",
    add: "Add",
    show_more: "Show more",
    show_less: "Show less",
    confirm: "Confirm",
    last_edit_at: "last edited on {{date}}",
    time: "",
    save_success: "Saved successfully.",
    save_error: "An error occurred while saving.",
  },
  favorites: {
    save_favorites: "Save favorite(s)",
    save_favorite: "Save favorite",
    custom_link: "Custom link",
    quick_link: "Quicklink",
    no_data: "No data available.",
    title: "Title",
    link: "Link",
  },
  think_tank: {
    page_title: "Pinnboard",
    request: "Requests",
    offer: "Offers",
    title: "Title*",
    description: "Description*",
    role: "Visible for",
    publish: "Publish",
    type: {
      title: "Type*",
      [ThinkTankType.OFFER]: "Offer",
      [ThinkTankType.REQUEST]: "Request",
    },
    category: "Category",
    [NONE_CATEGORY]: "None",
    delete_post: "Delete post",
    edit_post: "Edit post",
    dialog: {
      delete_title: "Delete post",
      confirm: "Do you really want to delete this post?",
    },
    update_error: "An error occurred while updating the post.",
    too_many_attachments: "You can only add up to 3 attachments.",
  },
  work_spaces: {
    webex_room: "To your personal webex room",
  },
  widget_color: {
    default: "Standard",
  },
  notifications: {
    status: {
      ALL: "All",
      [NotificationState.ACCEPTED]: "Confirmed",
      [NotificationState.UNACCEPTED]: "Unconfirmed",
      [NotificationState.READ]: "Read",
      [NotificationState.UNREAD]: "Unread",
    },
    read_notification: "Read confirmation",
    read_confirmation: "Please confirm here that you have read and taken note of the contents of the mailing.",
  },
  legal_register: {
    legal_basis: "Legal Basis",
    publication_date: "Publication MiBL",
    responsibility: "Responsibility",
    comment: "Comment",
    modification_date: "Amendment",
  },
  ck_editor: {
    init_error: "An error occurred while initializing the editor.",
    colors: {
      dark_green: "Dark green",
      light_green: "Light green",
      orange: "Orange",
      petrol: "Petrol",
      sunflower_yellow: "Sunflower yellow",
    },
  },
  roles: {
    [Role.ALL]: "All",
    [Role.EMPLOYEE]: "Employees",
    [Role.STUDENT]: "Students",
  },
  no_content: {
    infocenter: "This infocenter entry does not exist anymore.",
    infocenter_error: "An error ocurred while loading this infocenter entry. Please try again later.",
    infocenter_overview: "No infocenter entries could be found.",
    infocenter_overview_error: "An error ocurred while loading the infocenter entries. Please try again later.",
    notification_overview: "No mailings could be found",
    notification_overview_error: "An error occurred while loading mailings. Please try again later.",
    event: "This event does not exist anymore.",
    event_error: "An error ocurred while loading this event. Please try again later.",
    event_overview: "No events could be found.",
    event_overview_error: "An error ocurred while loading the events. Please try again later.",
    person: "This person could not be found.",
    person_error: "An error ocurred while loading this person. Please try again later.",
    document: "This document does not exist anymore.",
    topic: "This page does not exist anymore.",
    topic_page_detail_error: "An error ocurred while loading this content. Please try again later.",
    notification: "This mailing does not exist anymore.",
    notification_error: "This content is not available for you.",
    think_tank: "This post does not exist anymore.",
    legal_register_overview: "No legal documents could be found.",
    legal_register_overview_error: "An error ocurred while loading the legal register. Please try again later.",
    settings_error: "An error ocurred while loading the settings. Please try again later.",
  },
  widget_section: {
    update_error: "An error occurred while updating the widgets.",
  },
  notification_settings: {
    title: "Notifications",
    notifications: "Notifications for mailings",
    notifications_with_read_receipt: "Mailings with a mandatory read confirmation always appear as notification.",
    notifications_without_read_receipt: "Activate/Deactivate for mailings:",
  },
  bell_data_drawer: {
    title: "Notifications",
    no_notifications: "There are no new notifications.",
  },
};

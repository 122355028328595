import { Box, Divider } from "@mui/material";
import { KeyboardEvent, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { RestaurantMenu, useRestaurantMenusQuery } from "../../../api/muu-api/graphql/generated";
import { IExtendedWidget } from "../../../pages/dashboard/widget-section/types/widget.type";
import { LayoutContext } from "../../layout/layout.context";
import { filterNewRestaurantsByDate } from "./helpers/restaurants-filter.helper";
import { LunchMenusColumn } from "./lunch-menus-column.component";
import { WidgetLunchMenusInfo } from "../../../pages/dashboard/widget-section/menu-components/widget-lunch-info.component";

export interface ILunchMenusWidgetContentProps {
  widget: IExtendedWidget;
  isSmall: boolean;
}

export type DateShiftOptions = 1 | -1;

export const LunchMenusWidgetContent: React.FC<ILunchMenusWidgetContentProps> = (props) => {
  const { widget, isSmall } = props;
  const { breakpointInfo } = useContext(LayoutContext);

  const targetRef = useRef<HTMLDivElement>(null);
  const isMouseOverWidget = useRef(false);

  const [columnsAmount, setColumnsAmount] = useState<number>();

  const [baseDate, setBaseDate] = useState<Date>(new Date(new Date().setHours(0, 0, 0, 0)));

  // toDate is always baseDate + 1
  const toDate = useMemo(() => {
    const date = new Date(baseDate);

    if (date.getDay() === 5) {
      // friday; skip weekend
      date.setDate(date.getDate() + 3);
    } else {
      date.setDate(date.getDate() + 1);
    }

    return date;
  }, [baseDate]);

  // Calculate amount of columns to display
  useLayoutEffect(() => {
    if (targetRef.current) {
      const width = targetRef.current.offsetWidth;

      // Only display 2 columns if breakpoint is DESKTOP or DESKTOP_XL and width of the container is greater than 800
      const columns = !breakpointInfo.isMobile && width > 800 ? 2 : 1;
      setColumnsAmount(columns);
    }
  }, [breakpointInfo, targetRef.current?.offsetWidth]);

  // Fetch restaurants
  const {
    data: restaurantsData,
    loading: isDataLoading,
    refetch,
  } = useRestaurantMenusQuery({
    fetchPolicy: "no-cache",
    variables: {
      restaurantIds: widget.restaurantIds,
    },
  });

  // Refetch restaurants when dates change
  useEffect(() => {
    refetch();
  }, [widget.restaurantIds, baseDate, toDate, refetch]);

  // Split up restaurants, their meals and specials into days if there are more than one day was queried
  const splitRestaurantsByDates = useMemo(() => {
    const newRestaurants = (restaurantsData?.restaurantMenus as RestaurantMenu[]) || [];
    const newRestaurantsByDate = [];

    newRestaurantsByDate.push(filterNewRestaurantsByDate(newRestaurants, baseDate));
    newRestaurantsByDate.push(filterNewRestaurantsByDate(newRestaurants, toDate));

    return newRestaurantsByDate;
  }, [baseDate, toDate, restaurantsData?.restaurantMenus]);

  const handleSwitchDate = useCallback(
    (_dateShift: number) => {
      // Add the date shift to the base date

      let dateShift = _dateShift;

      if (baseDate.getDay() === 1 && dateShift === -1) {
        // monday; going back; skip weekend
        dateShift = -3;
      } else if (baseDate.getDay() === 5 && dateShift === 1) {
        // friday; going forward; skip weekend
        dateShift = 3;
      }

      const newDate = new Date(baseDate);
      newDate.setDate(newDate.getDate() + dateShift);
      setBaseDate(newDate);
    },
    [baseDate],
  );

  // Switch date on arrow key down
  const handleArrowKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!isMouseOverWidget.current) {
        return;
      }
      if (event.key === "ArrowLeft") {
        handleSwitchDate(-1);
      } else if (event.key === "ArrowRight") {
        handleSwitchDate(1);
      }
    },
    [handleSwitchDate],
  );

  return (
    <Box
      id="lunch-menu-content"
      ref={targetRef}
      onMouseEnter={() => {
        isMouseOverWidget.current = true;
      }}
      onMouseLeave={() => {
        isMouseOverWidget.current = false;
      }}
      sx={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {(breakpointInfo.isMobile || isSmall) && (
        <>
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <WidgetLunchMenusInfo isSmall={false} isInContent={true} widget={widget} />
          </Box>
          <Divider />
        </>
      )}
      <Box
        onKeyDown={handleArrowKeyDown}
        sx={{ display: "flex", columnGap: 4, flex: 1, height: isSmall || breakpointInfo.isMobile ? "80%" : "100%" }}
      >
        {/* First day */}
        <Box sx={{ maxHeight: "100%", width: "100%" }}>
          <LunchMenusColumn
            isFirstColumn
            isLastColumn={columnsAmount === 1}
            onSwitchDate={handleSwitchDate}
            date={baseDate}
            restaurants={(splitRestaurantsByDates.at(0) as RestaurantMenu[]) || []}
            isLoading={isDataLoading}
            currentColor={widget.color}
          />
        </Box>
        {/* Second day */}
        {columnsAmount === 2 && (
          <>
            <Divider flexItem orientation="vertical" sx={{ borderColor: "primaryDark.main", mt: 2, mb: 2 }} />
            <Box sx={{ maxHeight: "100%", width: "100%" }}>
              <LunchMenusColumn
                isLastColumn
                onSwitchDate={handleSwitchDate}
                date={toDate}
                restaurants={(splitRestaurantsByDates.at(1) as RestaurantMenu[]) || []}
                isLoading={isDataLoading}
                currentColor={widget.color}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

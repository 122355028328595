import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import { Fragment, FunctionComponent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { WidgetColor } from "../../../../../api/muu-api/graphql/generated";
import { ChangeColorIcon } from "../../../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../../../components/dialog/custom-dialog-title.component";
import { DashboardWidgetsContext } from "../../dashboard-widgets.context";
import { IWidgetMenuItemBaseProps } from "../../dictionaries/widget-menu.dictionary";
import { WidgetColorSelectionColor } from "./widget-color-selection-color.component";
import { WidgetColorSelectionDefaultColor } from "./widget-color-selection-default-color.component";
import { LayoutContext } from "../../../../../components/layout/layout.context";
import {
  WIDGET_COLOR_SELECTION_ITEM_HEIGHT,
  WIDGET_COLOR_SELECTION_SPACING_FACTOR,
  WIDGET_COLOR_SELECTION_WIDTH,
} from "../../../../../theme/sizings.theme";
import { CustomDialogActions } from "../../../../../components/dialog/custom-dialog-actions.component";

const WidgetColorsWithoutDefault = Object.values(WidgetColor).filter(
  (widgetColor) => widgetColor !== WidgetColor.DEFAULT,
);

interface IWidgetColorSelectionProps extends IWidgetMenuItemBaseProps {}

export const WidgetColorSelection: FunctionComponent<IWidgetColorSelectionProps> = (props) => {
  const { t } = useTranslation();
  const { onClose, widget } = props;

  const { breakpoint, breakpointInfo } = useContext(LayoutContext);
  const { saveWidget } = useContext(DashboardWidgetsContext);
  const [selectedColor, setSelectedColor] = useState<WidgetColor>(widget.color);

  const onApply = useCallback(() => {
    saveWidget({ ...widget, color: selectedColor });
    onClose();
  }, [saveWidget, widget, selectedColor, onClose]);

  const getJustify = useCallback((index: number) => {
    if ((index + 1) % 3 === 1) {
      return "flex-start";
    } else if ((index + 1) % 3 === 2) {
      return "center";
    }
    return "flex-end";
  }, []);

  const getMarginTop = useCallback(
    (index: number) => {
      if (index <= 2) {
        return 0;
      }
      return WIDGET_COLOR_SELECTION_SPACING_FACTOR[breakpoint];
    },
    [breakpoint],
  );

  return (
    <Fragment>
      <CustomDialogTitle
        title={t("widgets.menu_items.color")}
        iconProps={{ sx: { color: "primaryDark.main" }, size: "medium" }}
        Icon={ChangeColorIcon}
      />
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <WidgetColorSelectionDefaultColor
          isSelected={WidgetColor.DEFAULT === selectedColor}
          height={WIDGET_COLOR_SELECTION_ITEM_HEIGHT[breakpoint]}
          width={WIDGET_COLOR_SELECTION_WIDTH[breakpoint]}
          onChange={() => setSelectedColor(WidgetColor.DEFAULT)}
        />

        <Grid
          container
          sx={{
            width: WIDGET_COLOR_SELECTION_WIDTH[breakpoint],
            mt: WIDGET_COLOR_SELECTION_SPACING_FACTOR[breakpoint],
          }}
        >
          {WidgetColorsWithoutDefault.map((widgetColor, index) => (
            <Grid
              key={widgetColor}
              item
              container
              justifyContent={getJustify(index)}
              xs={4}
              sx={{ marginTop: getMarginTop(index) }}
            >
              <WidgetColorSelectionColor
                isSelected={widgetColor === selectedColor}
                onChange={setSelectedColor}
                size={WIDGET_COLOR_SELECTION_ITEM_HEIGHT[breakpoint]}
                widgetColor={widgetColor}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={onApply}>
          {t("general.apply")}
        </Button>
      </CustomDialogActions>
    </Fragment>
  );
};

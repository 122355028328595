import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { ThinkTankType, useSearchThinkTankQuery } from "../../../api/muu-api/graphql/generated";

export const THINK_TANK_LIMIT = 10;

export const useThinkTankWidgetPostsWithFetchMore = (
  ref: RefObject<HTMLElement>,
  type: ThinkTankType,
  categories: string[],
) => {
  const [fetchMoreOffset, setFetchMoreOffset] = useState<number>(THINK_TANK_LIMIT);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const {
    data,
    loading: isInitialQueryLoading,
    fetchMore,
    refetch,
  } = useSearchThinkTankQuery({
    variables: {
      limit: THINK_TANK_LIMIT,
      offset: 0,
      categories,
      dateRange: {
        from: null,
        to: null,
      },
      thinkTankType: type,
    },
  });

  const handleFetchMore = useCallback(
    (count: number) => {
      fetchMore({
        variables: {
          limit: THINK_TANK_LIMIT,
          offset: count,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.searchThinkTank.results.length === 0) return previousQueryResult;
          return {
            ...previousQueryResult,
            searchThinkTank: {
              ...previousQueryResult.searchThinkTank,
              results: previousQueryResult.searchThinkTank.results.concat(fetchMoreResult.searchThinkTank.results),
            },
          };
        },
      });
    },
    [fetchMore],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;

      if (
        scrollTop + clientHeight === scrollHeight &&
        !isInitialQueryLoading &&
        fetchMoreOffset < (data?.searchThinkTank.count ?? 0)
      ) {
        setIsLoadingMore(true);
        handleFetchMore(fetchMoreOffset);
        setFetchMoreOffset(fetchMoreOffset + THINK_TANK_LIMIT);
      }
    };
    const current = ref?.current;
    current?.addEventListener("scroll", handleScroll);
    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, [
    isInitialQueryLoading,
    fetchMoreOffset,
    setFetchMoreOffset,
    data?.searchThinkTank.count,
    handleFetchMore,
    ref,
    categories,
  ]);

  useEffect(() => {
    refetch();
    setFetchMoreOffset(THINK_TANK_LIMIT);
  }, [refetch]);

  const searchResults = useMemo(() => {
    setIsLoadingMore(false);
    return data?.searchThinkTank.results || [];
  }, [data]);

  return { isLoadingMore, isInitialQueryLoading, setIsLoadingMore, searchResults };
};

import { createContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchFilterName } from "../../../../api/muu-api/graphql/generated";
import {
  FilterAllIcon,
  FilterDocumentsIcon,
  FilterEventsIcon,
  FilterNewsIcon,
  FilterNotificationsIcon,
  FilterTopicsIcon,
  FilterUserIcon,
  ISvgIconBaseProps,
  OrganizationalUnitIcon,
  ThinkTankIcon,
} from "../../../../assets/icons/icons.component";

export interface ISearchFilter {
  id: number;
  indexName: SearchFilterName;
  title: string;
  icon: React.FC<ISvgIconBaseProps>;
}

interface ISearchContextProviderProps {
  children?: React.ReactNode;
}

interface ISearchContext {
  setSelectedFilter: (filter: ISearchFilter) => void;
  selectedFilter: ISearchFilter;
  filterItems: ISearchFilter[];
}

export const SearchContext = createContext<ISearchContext>({} as ISearchContext);

export const SearchContextProvider: React.FC<ISearchContextProviderProps> = (props) => {
  const { children } = props;
  const { t } = useTranslation();

  const filterItems = useMemo(
    () => [
      {
        id: 1,
        indexName: SearchFilterName.ALL,
        title: t(`search.filter.${SearchFilterName.ALL}`),
        icon: FilterAllIcon,
      },
      {
        id: 2,
        indexName: SearchFilterName.PERSONS,
        title: t(`search.filter.${SearchFilterName.PERSONS}`),
        icon: FilterUserIcon,
      },
      {
        id: 3,
        indexName: SearchFilterName.ORGANIZATIONAL_UNITS,
        title: t(`search.filter.${SearchFilterName.ORGANIZATIONAL_UNITS}`),
        icon: OrganizationalUnitIcon,
      },
      {
        id: 4,
        indexName: SearchFilterName.INFOCENTER,
        title: t(`search.filter.${SearchFilterName.INFOCENTER}`),
        icon: FilterNewsIcon,
      },
      {
        id: 5,
        indexName: SearchFilterName.EVENTS,
        title: t(`search.filter.${SearchFilterName.EVENTS}`),
        icon: FilterEventsIcon,
      },
      {
        id: 6,
        indexName: SearchFilterName.NOTIFICATIONS,
        title: t(`search.filter.${SearchFilterName.NOTIFICATIONS}`),
        icon: FilterNotificationsIcon,
      },
      {
        id: 7,
        indexName: SearchFilterName.TOPIC_PAGES,
        title: t(`search.filter.${SearchFilterName.TOPIC_PAGES}`),
        icon: FilterTopicsIcon,
      },
      {
        id: 8,
        indexName: SearchFilterName.LEGAL_DOCUMENTS,
        title: t(`search.filter.${SearchFilterName.LEGAL_DOCUMENTS}`),
        icon: FilterDocumentsIcon,
      },
      // See MUU-994 for more information
      // Will possibly be added again
      // {
      //   id: 9,
      //   indexName: SearchFilterName.THINK_TANK,
      //   title: t(`search.filter.${SearchFilterName.THINK_TANK}`),
      //   icon: ThinkTankIcon,
      // },
    ],
    [t],
  );
  const [selectedFilter, setSelectedFilter] = useState<ISearchFilter>(filterItems[0]);

  return (
    <SearchContext.Provider
      value={{
        setSelectedFilter,
        selectedFilter,
        filterItems,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

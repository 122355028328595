import { Box, Button, useTheme } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import { WidgetColor } from "../../../../../api/muu-api/graphql/generated";
import { CheckIcon } from "../../../../../assets/icons/icons.component";
import { hexToRgb } from "../../../../../utils/color.util";
import { useWidgetColorToThemeColor } from "../../hooks/use-widget-color-to-theme-color.hook";

interface IWidgetColorSelectionColorProps {
  widgetColor: WidgetColor;
  size: number;
  onChange: (color: WidgetColor) => void;
  isSelected: boolean;
}

export const WidgetColorSelectionColor: FunctionComponent<IWidgetColorSelectionColorProps> = (props) => {
  const { widgetColor, size, onChange, isSelected } = props;

  const theme = useTheme();

  const colorKey = useWidgetColorToThemeColor(widgetColor, false, true);
  const colorAsRgb = useMemo(() => hexToRgb(theme.palette[colorKey].main), [theme, colorKey]);

  return (
    <Button
      onClick={() => onChange(widgetColor)}
      variant={"contained"}
      sx={{
        height: size,
        width: size,
        backgroundColor: `${colorKey}.main`,
        "&:hover": {
          backgroundColor: `${colorKey}.main`,
          boxShadow: `4px 4px 8px 0px rgba(${colorAsRgb?.r},${colorAsRgb?.g},${colorAsRgb?.b}, 0.32)`,
        },
        borderRadius: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        boxShadow: isSelected
          ? `4px 4px 8px 0px rgba(${colorAsRgb?.r},${colorAsRgb?.g},${colorAsRgb?.b}, 0.32)`
          : undefined,
      }}
    >
      {isSelected && (
        <CheckIcon width={theme.icon.small} height={theme.icon.small} sx={{ color: `${colorKey}.contrastText` }} />
      )}
    </Button>
  );
};

import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { LayoutContext } from "../../components/layout/layout.context";

interface ILegalDocumentDetailProps {
  description: string;
  value: string;
}

export const LegalDocumentDetail: React.FC<ILegalDocumentDetailProps> = (props) => {
  const { description, value } = props;

  const { breakpointInfo } = useContext(LayoutContext);

  return (
    <span>
      <Typography variant="body2" display="inline" sx={{ color: "grey.600", fontWeight: 100 }}>
        {description}:&nbsp;
      </Typography>
      {breakpointInfo.isMobile && <br />}
      <Typography variant="body2" display="inline" sx={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </span>
  );
};

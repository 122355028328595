import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../../components/layout/layout.context";
import { DIALOG_MARGIN_FACTOR, WIDGET_DIALOG_WIDTH } from "../../../theme/sizings.theme";
import { CheckIcon } from "../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../components/dialog/custom-dialog-title.component";
import { CustomDialogActions } from "../../../components/dialog/custom-dialog-actions.component";

interface INotificationConfirmationDialogProps {
  handleClose: () => void;
  handleConfirmation: () => void;
  isOpen: boolean;
}

export const NotificationConfirmationDialog: React.FC<INotificationConfirmationDialogProps> = (props) => {
  const { handleClose, handleConfirmation, isOpen } = props;
  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);

  return (
    <Dialog
      id="notfication-confirmation-dialog"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { width: WIDGET_DIALOG_WIDTH[breakpoint], minWidth: "auto", p: 0, m: DIALOG_MARGIN_FACTOR[breakpoint] },
      }}
    >
      <CustomDialogTitle
        title={t("notifications.read_notification")}
        Icon={CheckIcon}
        iconProps={{ sx: { color: "primaryDark.main" } }}
      />
      <DialogContent>
        <Typography variant="body1">{t("notifications.read_confirmation")}</Typography>
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirmation}>
          {t("general.confirm")}
        </Button>
      </CustomDialogActions>
    </Dialog>
  );
};

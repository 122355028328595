import { Box, Grid } from "@mui/material";
import parse from "html-react-parser";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AttendenceFeeIcon,
  AttendenceRegisterIcon,
  AttendenceReservationIcon,
  CalendarExportIcon,
  DownloadIcon,
  WebsiteIcon,
} from "../../assets/icons/icons.component";
import { ContentSubtitle } from "../../components/content/content.subtitle";
import { ContentTitle } from "../../components/content/content.title";
import { i18n } from "../../translations/i18n";
import { formatShortDateTime } from "../../utils/date.util";
import { ICalEvent, formatICalEventDateTime, generateICalFromEvent } from "../../utils/ical.util";
import { EventAttendanceInfo } from "../events/event-attendance-info.component";
import { EventClickLink } from "../events/event-click-link.component";
import { EventLink } from "../events/event-link.component";
import { EventsDetailContext } from "./context/events-detail.context";
import { EventDetailInfoTable } from "./table/event-detail-info.table";
import { EditorContent } from "../../components/editor/editor-content.component";
import { CategoryChips } from "../../components/categories/category-chips.component";
import { PAGE_PAPER_PADDING_HORIZONTAL_FACTOR, PAGE_PAPER_PADDING_VERTICAL_FACTOR } from "../../theme/sizings.theme";
import { LayoutContext } from "../../components/layout/layout.context";
import { UserContext } from "../../app/context/user.context";

interface IEventsDetailContentProps {}

export const EventsDetailContent: React.FC<IEventsDetailContentProps> = (props) => {
  const { event: eventFromContext, favoriteInputs, pageContentRef, eventLoading } = useContext(EventsDetailContext);
  const { t } = useTranslation();
  const { breakpoint } = useContext(LayoutContext);
  const { language } = useContext(UserContext);

  const event = useMemo(() => eventFromContext!, [eventFromContext]);

  const subtitle = useMemo(() => {
    if (!event.startTime) return null;
    return `${formatShortDateTime(event.startTime, language)}${t("general.time")}${
      event.endTime ? " - " + formatShortDateTime(event.endTime, language) + t("general.time") : ""
    }`;
  }, [event, t, language]);

  const onExportICal = useCallback(() => {
    const iCalEvent: ICalEvent = {
      summary: event.title,
      start: formatICalEventDateTime(event.startTime, language),
      end: formatICalEventDateTime(event.endTime, language),
      description: event.teaser ?? "",
      location: event.location ?? "",
      url: event.organizerWebsite ?? "",
    };
    const blob = generateICalFromEvent(iCalEvent);

    const a = document.createElement("a");
    a.download = `event.ical`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }, [event, language]);

  return (
    <Box ref={pageContentRef}>
      <Grid
        container
        spacing={4}
        direction="column"
        sx={{
          pt: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pb: PAGE_PAPER_PADDING_VERTICAL_FACTOR[breakpoint],
          pl: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
          pr: PAGE_PAPER_PADDING_HORIZONTAL_FACTOR[breakpoint],
        }}
      >
        <Grid item>
          <ContentTitle
            loading={false}
            title={event.title}
            pageContentRef={pageContentRef}
            favoriteInputs={favoriteInputs}
          />
          {subtitle && <ContentSubtitle subtitle={subtitle} />}
        </Grid>
        <Grid item>
          {event?.media && <img style={{ objectFit: "cover", width: "100%" }} src={event.media.mediaUrl} alt="" />}
        </Grid>
        <Grid item container spacing={1}>
          {event.hasAttendenceRegistration && (
            <EventAttendanceInfo
              startAdornment={<AttendenceReservationIcon size="medium" />}
              title={t("events.attendence_registration")}
              description={
                event.registrationDeadline
                  ? `(${t("events.registration_deadline_info")} ${formatShortDateTime(
                      event.registrationDeadline,
                      i18n.language,
                    )})`
                  : null
              }
            />
          )}
          {event.hasAttendenceFee && (
            <EventAttendanceInfo
              startAdornment={<AttendenceFeeIcon size="medium" />}
              title={t("events.attendence_fee")}
              description={event.attendenceFee ? `(${parse(event.attendenceFee)})` : null}
            />
          )}
        </Grid>
        <Grid item sx={{ pb: 4 }}>
          <EventDetailInfoTable
            organizer={event.organizer}
            location={event.location}
            contactPerson={event.contactPerson}
            email={event.email}
            dfpPoints={event.dfpPoints}
          />
        </Grid>
        <Grid item container spacing={6} justifyContent="flex-start">
          {event.registrationLink && (
            <EventLink
              startAdornment={<AttendenceRegisterIcon size="medium" sx={{ color: "error.main" }} />}
              link={event.registrationLink}
              title={t("events.registration")}
            />
          )}
          {event.organizerWebsite && (
            <EventLink
              startAdornment={<WebsiteIcon size="medium" sx={{ color: "error.main" }} />}
              link={event.organizerWebsite}
              title={t("events.website")}
            />
          )}
          {event.attachments.length > 0 &&
            event.attachments.map((attachment) => (
              <EventLink
                key={`attachment-${attachment.id}`}
                startAdornment={<DownloadIcon size="medium" sx={{ color: "error.main" }} />}
                link={attachment.mediaUrl}
                title={attachment.mediaTitle ?? t("events.attachment_default_name")}
              />
            ))}
          <EventClickLink
            startAdornment={<CalendarExportIcon size="medium" sx={{ color: "error.main" }} />}
            onClick={onExportICal}
            title={t("events.ical_export")}
          />
        </Grid>
        <Grid item>{event.body && <EditorContent editorContentHtmlString={event.body} />}</Grid>
        <Grid item>
          <CategoryChips
            additionalCategories={event.additionalCategories}
            mainCategory={event.mainCategory}
            loading={eventLoading}
            type="events"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import { Grid, Skeleton, Stack, useTheme } from "@mui/material";
import { FC, Fragment, useCallback } from "react";

interface DefaultWidgetSkeletonProps {
  rows: number;
}

export const DefaultWidgetSkeleton: FC<DefaultWidgetSkeletonProps> = (props) => {
  const { rows } = props;
  const theme = useTheme();

  const getRow = useCallback(
    (index: number) => {
      return (
        <Grid key={index} container direction="row" spacing={1} sx={{ p: 1, width: "100%" }}>
          <Grid item xs={1} sm={1}>
            <Skeleton variant="text" width="100%" height={44} />
          </Grid>
          <Grid item xs={11} sm={11}>
            <Skeleton variant="text" width="100%" height={22} sx={{ fontSize: theme.typography.body1 }} />
            <Skeleton variant="text" width="100%" height={22} sx={{ fontSize: theme.typography.body1 }} />
          </Grid>
        </Grid>
      );
    },
    [theme],
  );

  return <Stack sx={{ width: "100%" }}>{[...Array(rows)].map((e, i) => getRow(i))}</Stack>;
};

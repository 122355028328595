import { useApolloClient } from "@apollo/client";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FavoritesForUserDocument,
  FavoriteSource,
  FavoriteType,
  QuickLink,
  useCreateFavoritesMutation,
} from "../../../../api/muu-api/graphql/generated";
import { PlusIcon } from "../../../../assets/icons/icons.component";
import { CustomDialogTitle } from "../../../../components/dialog/custom-dialog-title.component";
import { AddFavoriteLink } from "../../../../components/favorite/add-favorite-link/add-favorite-link.component";
import { ICreateCustomLink } from "../../../../components/favorite/types/create-link.type";
import { writeToFavoritesCache } from "../../../../components/favorite/helper/favorite-cache.helper";
import { useFavorite } from "../../../../hooks/use-favorite.hook";
import { IWidgetMenuItemBaseProps } from "../dictionaries/widget-menu.dictionary";
import { CustomDialogActions } from "../../../../components/dialog/custom-dialog-actions.component";

interface IWidgetAddLinkFavoriteProps extends IWidgetMenuItemBaseProps {}

export const WidgetAddLinkFavorite: FC<IWidgetAddLinkFavoriteProps> = (props) => {
  const { onClose } = props;

  const [customLinks, setCustomLinks] = useState<ICreateCustomLink[]>([]);
  const [quickLinks, setQuickLinks] = useState<QuickLink[]>([]);

  const { t } = useTranslation();
  const client = useApolloClient();
  const [createFavoritesMutation] = useCreateFavoritesMutation();
  const { getEmptyFavorite } = useFavorite();

  const isSaveButtonDisabled = useMemo(
    () => customLinks.length === 0 && quickLinks.length === 0,
    [customLinks, quickLinks],
  );

  const customLinksToCreateInput = useCallback(() => {
    return customLinks.map((link) => {
      return {
        entryId: link.tempId,
        description: link.title ?? "",
        link: link.link,
        type: FavoriteType.CUSTOM_LINK,
        source: FavoriteSource.API,
      };
    });
  }, [customLinks]);

  const quickLinksToCreateInput = useCallback(() => {
    return quickLinks.map((link) => {
      return {
        entryId: link.id,
        description: link.title ?? "",
        type: FavoriteType.QUICK_LINK,
        source: FavoriteSource.STRAPI,
        link: link.link,
      };
    });
  }, [quickLinks]);

  const onSaveClicked = useCallback(() => {
    const quickLinksCreateInput = quickLinksToCreateInput();
    const customLinksCreateInput = customLinksToCreateInput();
    const emptyCustomLinkFavorites = customLinksCreateInput.map((link) => getEmptyFavorite(link));
    const emptyQuickLinkFavorites = quickLinksCreateInput.map((link) => getEmptyFavorite(link));

    writeToFavoritesCache(client, (favorites) => {
      return [...favorites, ...emptyCustomLinkFavorites, ...emptyQuickLinkFavorites];
    });
    createFavoritesMutation({
      variables: { favorites: [...quickLinksCreateInput, ...customLinksCreateInput] },
      onCompleted() {
        client.refetchQueries({
          include: [FavoritesForUserDocument],
        });
      },
    });
    onClose();
    setCustomLinks([]);
    setQuickLinks([]);
  }, [client, createFavoritesMutation, onClose, getEmptyFavorite, customLinksToCreateInput, quickLinksToCreateInput]);

  return (
    <>
      <CustomDialogTitle
        title={t("widgets.menu_items.add_link_favorite")}
        Icon={PlusIcon}
        iconProps={{ sx: { color: "primaryDark.main" } }}
      />
      <DialogContent>
        <AddFavoriteLink
          customLinks={customLinks}
          setCustomLinks={setCustomLinks}
          quickLinks={quickLinks}
          setQuickLinks={setQuickLinks}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" onClick={onSaveClicked} disabled={isSaveButtonDisabled}>
          {t("favorites.save_favorites")}
        </Button>
      </CustomDialogActions>
    </>
  );
};
